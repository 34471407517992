<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mobile_tab_max_width_flex">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              v-model="siteData"
              @input="filterTable"
              :clearable=false
            >
            <template #selected-option="{ site_name }">
              <span v-if="site_name.length < 23">{{site_name}}</span>
              <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
            </template>
          </v-select>
          </b-col>


          <!-- <b-col 
           cols="12"
           md="9"
           class="mb-md-0 mb-2"
          >

            <div class="d-flex align-items-center justify-content-end">

              


            </div>
          </b-col> -->
          
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Search..."
                @input="filterTable"
              />
              <!-- <b-button
                variant="warning"
                :to="{name:'add-job-sites'}"
              >
                <span class="text-nowrap">Add Project Site</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->
        <template #cell(form_name)="items">
           
            <span class="wordBreak">
              <div class="my-1">{{ items.item.form_name | capitalize}}</div>
            </span>
         
        </template>

        <template #cell(site)="items">
           
            <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
         
        </template>

        
        <template #cell(actions)="items">

          <b-link 
            v-if="checkPermission($route.name, 'View') && (items.item.type == 'checklist' || items.item.type == 'qi_checklist')"
            v-b-tooltip.hover.v-warning
            title="View Detail"
            variant="outline-warning"
            :to="{name:'view-checklists', params: { id: items.item._id}}"
          >
              <feather-icon icon="EyeIcon" class="mr-1 mediumSize"/>
              
          </b-link>

          <b-link 
            v-if="checkPermission($route.name, 'View') && (items.item.type == 'QA')"
            v-b-tooltip.hover.v-warning
            title="View Detail"
            variant="outline-warning"
            :to="{name:'view-qa-checklists', params: { id: items.item._id}}"
          >
              <feather-icon icon="EyeIcon" class="mr-1 mediumSize"/>
              
          </b-link>


          
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BCardBody,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
       tableColumns: [
            { key: 'form_name', label: 'Checklists', sortable: true , thStyle:  {width: '40%'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '40%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '20%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        sites:[],
        siteData:null
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     site:this.siteData ? this.siteData._id :null,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     type:'checklist'
                   },
                   api: '/api/assigned-checklists-form'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              
              // if (this.sites.length == 1 ) {
              //     this.siteData = this.sites[0];

              //     this.filterTable();
              // }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites'
              }
              this.sites.unshift(obj);

              this.siteData = this.defaultSite(this.sites);
					    this.filterTable();
              
              return this.sites;
          }
      });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:null,
        text: 'Create Checklists',
        active:true
      }];
      return item;
    }
  },
  mounted() {
    this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
